import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import LighthouseLogo from '../assets/lighthouse-logo.svg'
import Header from '../components/Header'
import Footer from '../components/Footer'

export const query = graphql`
    query($slug: String!) {
        projectsJson(slug: { eq: $slug }) {
            slug
            title
            timespan
            tools
            description
        }
    }
`

const ProjectPage = ({ data }) => {
    const project = data.projectsJson

    return (
        <React.Fragment>
            <Helmet
                htmlAttributes={{
                    lang: 'en',
                }}
            >
                <meta charSet="utf-8" />
                <title>Amol Garhwal | {project.title}</title>
                <meta name="description" 
                content={project.description} />
            </Helmet>
            <Header />
            <div className="container ck-container-detail-project">
                <div className="row">
                    <div className="col-lg-11 col-sm-10">
                        <h1 className="ck-detail-project-title">{project.title}</h1>
                    </div>
                    <div className="col-lg-1 col-sm-2 ck-lighthouse-logo-div">
                        <a href={`/project/${project.slug}/lighthouse.html`} target="_blank" rel="noreferrer" className={`${project.slug === 'blockchain' ? 'hide-visibility' : ''}`}>
                            <img src={LighthouseLogo} width="50px" alt="lighthouse-report" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Lighthouse Report" />
                        </a>
                    </div>
                </div>
                <h5 className="ck-detail-project-timespan">{project.timespan}</h5>
                <div className="row">
                    <div className="col-lg-2 col-md-3 col-sm-4">
                        <div className="col-sm-6" style={{ paddingLeft: 0}}>
                            <h3 className="ck-detail-project-tools">{project.tools.replace(/\s\|\s/mg,' ')}</h3>
                            <h3 className="ck-detail-project-tools-small">{project.tools}</h3>
                        </div>
                    </div>
                    <div className="col-lg-10 col-md-9 col-sm-8">
                        <p className="ck-detail-project-description" dangerouslySetInnerHTML={{ __html: project.description }} />
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    )
}

export default ProjectPage